import styled from "styled-components";

export const SmallTalkContainer = styled.div`
  
  width:98%;
  margin: 0.5% auto 0 auto;
  height: 20vh;
  min-height:150px;
  div {
    height: 100%;
    width: 100%;

    ul {
      height: 100%;
      li {
        margin-right: 10px;
        
      }
    }

    button {
      background: rgba(0, 0, 0, 0.7);
    }
  }
  @media only screen and (max-width: 1024px) {
    div {
      ul {
        li {
          /* margin-right: 0%; */
        }
      }
    }
  }
`;

export const SmallTalkItem = styled.div`
  height: 100%;
  width: 100%;
  div {
    a {
      :hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
`;
export const h1 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  margin: auto;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

  :hover {
    background-color: rgba(0, 0, 0, 0);
  }
  h2 {
    width: 90%;
    margin: auto;
    font-size: 1.2em;
    color: white;
    text-align: center;
    filter: drop-shadow(2px 4px 6px black);
  }
`;