import React from "react"
import * as Style from "../css/smalltalkStyle"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import getLang from "./scripts/getLang";

const SmallTalkComponent = ({english}) => {
  let data = useStaticQuery(
    graphql`
      query {
        allContentfulSmallTalks(sort: { fields: [updatedAt], order: DESC }) {
          edges {
            node {
              updatedAt(formatString: "YY-MM/DD  h:mm:ss")
              node_locale
              slug
              rubrik
              text {
                childContentfulRichText {
                  html
                }
              }
              bild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `
  )
  let smallTalk = getLang(english, data.allContentfulSmallTalks)
  let RenderSmalltalk = smallTalk.map((item, index) => {
    return (
      <Style.SmallTalkItem key={index}>
        <BackgroundImage
          Tag="div"
          className={"SmallTalk"}
          fluid={item.node.bild.fluid}
          backgroundColor={`#040e18`}
        >
         <Link to={`/smalltalks/${item.node.slug}/`}>
         <Style.h1>
            <h2>{item.node.rubrik.toUpperCase()}</h2>
         </Style.h1>
          </Link>
        </BackgroundImage>
      </Style.SmallTalkItem>
    )
  })

  const responsive = {
    widescreen: {
      breakpoint: { max: 3000, min: 1800 },
      items: 5,
      paritialVisibilityGutter: 20,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
      paritialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      paritialVisibilityGutter: 40,
    },
  }

  return (
    <Style.SmallTalkContainer>
      <Carousel
        width={"50vw"}
        ssr
        partialVisbile
        itemClass="image-item"
        swipeable={true}
        responsive={responsive}
      >
        {RenderSmalltalk}
      </Carousel>
    </Style.SmallTalkContainer>
  )
}

export default SmallTalkComponent
