import styled from "styled-components";

export const Container = styled.div`
  margin: 2% auto 0 auto;
  font-size: 1em;
  width:98%;

  @media only screen and (max-width: 992px) {
    margin: 10% auto 0 auto;
    text-align: center;
    p:first-of-type {
      width: 100%;
      margin: auto;
    }
  }
  @media only screen and (max-width: 992px) and (orientation: landscape) {
    margin: 10% auto 0 auto;
    text-align: center;
    p:first-of-type {
      width: 100%;
      margin: auto;
    }
  }
`;

export const Content = styled.div`
  margin: 2% 1%;
  font-size: 20px;
  h1 {
    font-weight: 900;
  }
`;
export const FlexH1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  transition-duration: 0.4s;
  color: #e8e8e8;
  width: 100%;
  text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
  background-color: ${props => props.seasonClosed ? 'rgba(0, 0, 0, 0.7)' : null };
  

  p {
    margin: 0;
    filter: drop-shadow(2px 4px 6px black);
    font-family:StureplansgruppenUnderRubrik;
    text-align:center;
  }

  .subtitle {
    height: auto !important;
    font-size: 18px;
  }

  h2, h3, h4 {
    margin: 5px 0 !important;
    font-size: 25px !important;
  }

  :hover {
    background-color: ${props => props.seasonClosed ? null : 'rgba(0, 0, 0, 0.4)' };
  }
`;

export const H2 = styled.h2`
  color: #e8e8e8;
  text-align: center;
  margin: 0;
  padding: 5% 10px 2% 10px;
  font-size: 30px;
  filter: drop-shadow(2px 4px 6px black);
`;
export const CenterDiv = styled.div`
  margin: auto;
  display: flex;
  margin-bottom: 2em;
  justify-content: center;
`;

export const FlexContainer = styled.div`
  margin: auto;
  margin-top: 3%;
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;

  .orderOne {
    order: 1 !important;
    width: 600px;
    min-width: 300px;
  }
  .extraLarge {
    width: 1200px;
    margin: 3px auto;
  }
  a {
    width: 300px;
    order: 2;
    @media (max-width: 630px) {
      width: 90%;
      min-width: 300px;
      margin: 3px auto;
    }
  }

  .cardFlex {
    height: 300px;
    min-width: 300px;
    width: 100%;
    opacity: 0;
    animation: cardFlexFader 1.5s;
    p {
      font-family: StureplansgruppenUnderRubrik;
      filter: drop-shadow(2px 4px 6px black);
    }
  }

  @media (min-width: 1537px) {
    width: fit-content;
    max-width: 1200px;
  }
  @media (max-width: 1536px) {
    width: fit-content;
    max-width: 1200px;
  }
  @media (max-width: 1250px) {
    width: fit-content;
    max-width: 900px;

    justify-content: ${props =>
      props.page === "hotel" ? "center" : "flex-start"};
    * {
      margin: 0 !important;
    }
  }
  @media (max-width: 920px) {
    width: fit-content;
    max-width: 600px;
    * {
      margin: 0 !important;
    }
    .orderOne {
      max-width: 600px;
    }
  }
  @media (max-width: 630px) {
    justify-content: center;
    * {
      margin: 0 !important;
      padding: 0;
    }
    .orderOne {
      min-width: 300px;
      width: 90%;
      margin: 3px auto !important;
    }
  }

  .cardLokal {
    height: 30vh;
    min-height: 300px;
    width: 1200px;
    min-width: 600px;

    :hover {
      text-decoration: none;
    }
    @media (max-width: 1250px) {
      width: 100%;
    }
    @media (max-width: 630px) {
      width: 300px;
      min-width: 300px;
    }
    @keyframes cardFlexFader {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    width: 100;
    .cardFlex {
      margin: 0 0 1% 0;
      width: 100%;
    }
    .cardLokal {
      min-width: 600;
      width: 100%;
      margin: 0 0 1% 0;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    .cardFlex {
      margin: 0 0 1% 0;
    }
    .cardLokal {
      min-width: 600;
      width: 100%;
      margin: 0 0 1% 0;
    }
  }
`;

export const Searchbar = styled.div`
  margin-top: 3%;
  width: 1200px;
  display: flex;
  margin: auto;
  flex-direction: column;

  /* width on search bar to match the flex-grid */
  @media(max-width: 1250px){
    max-width: 900px;
  }
  @media(max-width: 920px){
    max-width: 600px;
  }
  @media(max-width: 600px){
    max-width: 90%;
  }
`;

export const button = styled.button`
  color: white;
  text-align: center;
  background-color: #181818;
  font-size: 1rem;
  padding: 12px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

  :hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
`;
